"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  uR.auth = uR.auth || {};
  uR.auth.loginRequired = function loginRequired(func, data) {
    if (typeof func == "string") {
      var tagname = func;
      func = function func(path, data) {
        uR.mountElement(tagname, data);
      };
    }
    data = data || {};
    function wrapped() {
      var args = arguments;
      uR.auth.ready(function () {
        function success(data) {
          if (data) {
            uR.auth.setUser(data.user);
          }
          func.apply(this, args);
        }
        if (!uR.auth.user || data.force) {
          uR.AUTH_SUCCESS = success;
          data.next = window.location.href;
          uR.route(uR.urls.auth.login, data);
        } else {
          success();
        }
      });
    }
    wrapped.login_required = true;
    return wrapped;
  };
  uR.auth.setUser = function setUser(user) {
    uR.storage.set('auth.user', user || null); // JSON.stringify hates undefined
    uR.auth.user = user;
    uR.auth.postAuth();
    riot.update(uR.auth.tag_names);
  };
  uR.auth.postAuth = function () {};
  uR.auth._getLinks = function () {
    return [{ url: "/accounts/logout/", icon: "sign-out", text: "Log Out" }];
  };
  uR.auth.getLinks = uR.auth._getLinks;
  uR.auth.auth_regexp = /\/auth\//;

  uR.schema.auth = {
    login: [{ name: 'username', label: 'Username or Email' }, { name: 'password', type: 'password' }],
    register: [{ name: 'email', label: 'Email Address', type: "email" }, { name: 'password', type: 'password' }],
    'password-reset': [{ name: 'email', label: 'Email Address', type: "email" }]
  };
  uR.urls.auth = {
    login: "#/auth/login/",
    register: "#/auth/register/",
    password_reset: "#/auth/forgot-password/"
  };
  uR.urls.api = uR.urls.api || {};
  uR.urls.api.login = "/auth/login_ajax/";
  uR.urls.api.register = "/api/register/"; // #! TODO
  uR.urls.api['password-reset'] = "/api/password-reset/"; // #! TODO
  uR.auth.tag_names = 'auth-dropdown,auth-modal';
  uR.addRoutes({
    "[?#]?/auth/(login|register|forgot-password)/": function authLoginRegisterForgotPassword(path, data) {
      uR.alertElement("auth-modal", data);
    },
    "[?#]?/auth/logout/": function authLogout(path, data) {
      uR.auth.setUser(null);
      uR.route("/accounts/logout/");
    }
  });

  uR.auth.user = uR.storage.get("auth.user");
  uR.ready(function () {
    riot.mount(uR.auth.tag_names);
    !uR.config.no_auth && uR.auth.reset();
  });
  var _ready = [];
  uR.auth.ready = function (f) {
    _ready.push(f);
  };
  uR.auth.reset = function (callback) {
    if (!uR.auth_enabled) {
      return;
    }
    callback = callback || function () {};
    uR.ajax({
      url: "/user.json",
      success: function success(data) {
        if (data.user != uR.auth.user) {
          uR.auth.setUser(data.user);
        }
        callback();
        uR.auth.ready = function (f) {
          f();
        };
        uR.forEach(_ready, uR.auth.ready);
      }
    });
  };
})();

riot.tag2('auth-modal', '<div class="{theme.outer}"> <div class="{theme.header}"> <h3>{title}</h3> </div> <div class="{theme.content}"> <div class="social" if="{slug != \'fogot_password\' && uR.auth.social_logins.length}"> <a class="btn btn-block btn-{icon}" href="{url}?next={next}" each="{uR.auth.social_logins}"> <i class="fa fa-{icon}"></i> Connect with {name}</a> <center>- or {slug} using your email address -</center> </div> <ur-form schema="{schema}" action="{url}" method="POST" ajax_success="{opts.success}"></ur-form> <center if="{slug == \'login\'}"> <a href="{urls.register}?next={next}">Create an Account</a><br> <a href="{urls.password_reset}?next={next}">Forgot Password?</a> </center> <center if="{slug == \'register\'}"> Already have an account? <a href="{urls.login}?next={next}">Login</a> to coninue </center> <center if="{slug == \'password_reset\'}"> Did you suddenly remember it? <a href="{urls.login}?next={next}">Login</a> </center> </div> </div>', '', '', function (opts) {
  var self = this;
  this.ajax_success = function (data, request) {
    (uR.AUTH_SUCCESS || function () {
      var path = self.next || window.location.pathname;
      if (path.match(uR.auth.auth_regexp)) {
        path = "/";
      }
      uR.route(path);
    })(data, request);
    self.unmount();
    uR.AUTH_SUCCESS = undefined;
  }.bind(this);
  this.on("mount", function () {
    if (uR.auth.user) {
      this.ajax_success({ user: uR.auth.user });
    }
    this.next = uR.getQueryParameter("next");
    this.slug = this.opts.slug || this.opts.matches[1];
    this.url = uR.urls.api[this.slug];
    this.schema = uR.schema.auth[this.slug];
    this.title = {
      login: "Please Login to Continue",
      register: "Create an Account",
      'forgot-password': "Request Password Reset"
    }[this.slug];
    this.update();
  });
  this.on("update", function () {

    this.urls = uR.urls.auth;
    if (window.location.pathname.match(uR.auth.auth_regexp)) {
      this.urls = {};
      for (var key in uR.urls.auth) {
        this.urls[key] = uR.urls.auth[key].replace("#", "");
      }
    }
    if (uR.auth.user) {
      self.ajax_success({ user: uR.auth.user });
    }
  });
  this.cancel = function (e) {
    if (window.location.pathname.match(uR.auth.auth_regexp)) {
      uR.route("/");
    }
    window.location.hash = "";
    this.unmount();
  }.bind(this);
});

riot.tag2('auth-dropdown', '<li if="{!uR.auth.user}"> <a href="{url}?next={window.location.pathname}"><i class="{icon}"></i> {text}</a> </li> <li if="{uR.auth.user}"> <a onclick="{toggle}">{uR.auth.user.username}</a> <ul class="dropdown-content"> <li each="{links}"><a href="{url}"><i class="fa fa-{icon}"></i> {text}</a></li> </ul> </li>', '', '', function (opts) {

  this.on("update", function () {
    if (uR.auth.user) {
      this.links = uR.auth.getLinks();
    } else {
      this.url = uR.auth.login_url || uR.urls.auth.login;
      this.icon = uR.auth.login_icon || "fa fa-user";
      this.text = uR.auth.login_text || "Login or Register";
    }
  });
  this.toggle = function (e) {
    this.root.classList[this.root.classList.contains("open") ? "remove" : "add"]("open");
  }.bind(this);
});

(function () {
  var DialogMixin = {
    init: function init() {
      if (this.opts.ur_modal) {
        this.theme = this.opts.theme || uR.theme.modal;
        var e = document.createElement('div');
        this.cancel = this.cancel || this.opts.cancel || function () {
          this.unmount();
        };
        e.addEventListener("click", function () {
          this.cancel();
        }.bind(this));
        e.setAttribute("ur-mask", true);
        if (this.root.childNodes.length) {
          this.root.insertBefore(e, this.root.childNodes[0]);
        } else {
          this.root.appendChild(e);
        }
      } else {
        this.theme = this.opts.theme || uR.theme[this.root.tagName] || uR.theme.default;
      }
    }
  };

  riot.mixin(DialogMixin);

  uR.alert = function (text, data) {
    data = data || {};
    data.close_text = data.close_text || "Close";
    data.innerHTML = "<center style='margin-bottom: 1em;'>" + text + "</center>";
    uR.alertElement("ur-modal", data);
  };
  uR.confirm = function (text, data) {
    if (typeof data == 'function') {
      data = { success: data };
    }
    data = data || {};
    data.buttons = data.buttons || [];
    data.close_text = data.close_text || "No";
    data.buttons.push({
      onclick: data.success,
      className: uR.config.btn_success,
      text: data.success_text || "Yes"
    });
    data.innerHTML = "<center style='margin-bottom: 1em;'>" + text + "</center>";
    uR.alert(text, data);
  };
})();

riot.tag2('ur-modal', '<div class="{theme.outer}"> <div class="{theme.content}"> <div class="inner-content"></div> <yield></yield> <center> <button onclick="{close}" class="{uR.config.btn_primary}">{close_text}</button> <button each="{opts.buttons}" class="{className}" onclick="{_onclick}">{text}</button> </center> </div> </div>', '', '', function (opts) {

  var self = this;
  this.close_text = this.opts.close_text || "Close";
  this.on("mount", function () {
    uR.forEach(this.opts.buttons || [], function (b) {
      b._onclick = function (e) {
        b.onclick(e);self.unmount();
      };
    });
    self.update();
  });
  this.close = function (e) {
    this.opts.cancel && this.opts.cancel();
    this.unmount();
  }.bind(this);
});

(function () {
  uR.form = {};
  uR.theme['UR-FORM'] = uR.theme.default;
  uR.ready(function () {
    if (uR.config.form_prefix != undefined) {
      var _routes = {};
      _routes[uR.config.form_prefix + "/([\\w\\.]+[\\w]+)/(\\d+)?/?$"] = function (path, data) {
        var url = "/api/schema/" + data.matches[1] + "/";
        if (data.matches[2]) {
          url += data.matches[2] + "/";
        }
        uR.form.current_form = data.matches[1];
        data.schema = url + (location.search || "?ur_page=0");
        data.method = "POST"; // #! TODO this should be an option tied to python schema
        uR.mountElement("ur-form", data);
      };
      uR.addRoutes(_routes);
    }
  });
  uR.__START = new Date().valueOf();
  uR._t = function (s) {
    console.log(new Date().valueOf() - uR.__START, s);
  };
  uR.form.parseChoices = function (choices) {
    // #! TODO This should eventually accomodate groupings as well like:
    // choices = [["group_name",[choice1,choice2,choice3]...],group2,group3]
    return choices.map(function (c) {
      if (typeof c == "string") {
        return [c, c];
      }
      return c;
    });
  };
  riot.mixin({ // anything with a ur-input like tag needs the following
    init: function init() {
      if (!this.opts.is_ur_input) {
        return;
      }
      this.field = this.opts.field;
      this.on("mount", function () {
        setTimeout(this.field.reset.bind(this.field), 0);
      });
    }
  });
  uR.form.URForm = function () {
    function URForm(ur_form) {
      _classCallCheck(this, URForm);

      this.form_tag = ur_form;
      this.opts = ur_form.opts;
      this.messages = [];
      this.prepSchema();
    }

    _createClass(URForm, [{
      key: "prepSchema",
      value: function prepSchema() {
        var tag = this.form_tag;
        var _schema = tag.opts.schema || tag._parent.opts.schema || tag._parent.schema;
        this.action = tag.opts.action;
        if (typeof _schema == "string") {
          this.schema_url = _schema;
          this.action = this.action || this.schema_url;
          if (uR.schema[this.schema_url]) {
            _schema = uR.schema[this.schema_url];
          } else {
            var url = _schema;
            uR.getSchema(url, this.prepSchema.bind(this));
            this._needs_update = true;
            _schema = [];
            return;
          }
        }
        this.empty_initial = uR.schema.__initial[this.schema_url] || this.form_tag.opts.initial || {};
        this.initial = uR.storage.get(this.form_tag.action) || this.empty_initial || {};

        tag.form_title = this.opts.form_title || _schema.form_title;
        tag.rendered_content = _schema.rendered_content;
        this.schema = _schema.map(function (field) {
          if (typeof field == "string") {
            field = { name: field };
          }
          var f = {};
          for (var k in field) {
            f[k] = field[k];
          }
          return f;
        });
        this.field_list = [];
        this.fields = {};
        uR.forEach(this.schema, function (field, i) {
          field.tagname = uR.config.input_overrides[field.type] || "ur-input";
          field._field_index = this.field_list.length;
          var cls = uR.form.fields[field.tagname] || uR.form.fields["ur-input"];
          this.field_list.push(new cls(this, field));
          this.fields[field.name] = this.field_list[this.field_list.length - 1];
        }.bind(this));
        if (this._needs_update) {
          this.form_tag.update();
          this.form_tag.update();
        };
        /*
        this.update();
        this.update();
        if (this.fields.length && !opts.no_focus) {
          setTimeout(function() {
            var f = self.root.querySelector("input:not([type=hidden]),select,textarea");
            f && f.focus();
            (self.opts.post_focus || function() {})(self);
          },0)
          }
        */
      }
    }, {
      key: "renderFields",
      value: function renderFields() {
        if (this._fields_rendered) {
          return;
        }
        this._fields_rendered = true;
        var targets = this.form_tag.root.querySelectorAll(".ur-input");
        uR.forEach(this.field_list, function (field, i) {
          targets[i].insertBefore(field.field_tag.root, targets[i].firstElementChild);
        });
        this.opts.onload && this.opts.onload.bind(this)();
        this.active = true; // form can now show errors
      }
    }]);

    return URForm;
  }();

  uR.form.URInput = function () {
    function URInput(form, options) {
      _classCallCheck(this, URInput);

      this.tag_name = this.tag_name || "ur-input"; // can be overridden by sub-classes
      this.form = form;
      if (typeof options == "string") {
        var name = options;
        if (uR.schema.fields[options]) {
          options = uR.schema.fields[options];
          options.name = name;
        } else {
          options = { name: name, type: 'text' };
        }
      }
      for (var k in options) {
        this[k] = options[k];
      }
      this.required = this.required == undefined || this.required; // defaults to true!

      this.name = this.name || this.type;
      if (_typeof(this.name) == "object") {
        // can't remember when this is used
        console.warn("look at me!");
        this.name = _typeof(this.name) == "object" ? this.name[0] : this.name;
      }
      this.value = this.initial_value = this.value || (this.form.initial || {})[this.name];
      this.valid = true;
      // verbose_name is useful for error messages, other generated text
      this.verbose_name = this.verbose_name || this.label || this.placeholder;
      if (!this.verbose_name) {
        var replace = function replace(s) {
          return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
        };
        this.verbose_name = (this.name || "").replace(/[-_]/g, " ").replace(/\w\S*/g, replace);
      }
      this.label = this.label || this.verbose_name;
      this.id = this.id || "id_" + this.name + this.form.form_tag.suffix;
      this.input_tagname = this.input_tagname || this.type == "textarea" ? this.type : "input";
      this.input_type = this.type || "text";

      // if there's a validator, use type=text to ignore browser default
      if (uR.config.text_validators[this.name]) {
        this.validate = uR.config.text_validators[this.name];
        this.input_type = "text";
      }

      // It's easier to have an empty function than undefined, also make bouncy
      this.validate = this.validate || function () {
        return true;
      };
      //#! TODO: rethink bouncy validators as some kind of promise
      //this.validate = (this.bounce)?uR.debounce(this.validate.bind(f),this.bounce):this.validate;
      this.keyUp = this.keyUp || function () {};
      this.keyUp = this.bounce ? uR.debounce(this.keyUp.bind(f), this.bounce) : this.keyUp;

      // universal choice parser, maybe move to uR.form?
      if (this.choices) {
        this.choices_map = {};
        this.choices = uR.form.parseChoices(this.choices).map(function (choice_tuple, index) {
          this.choices_map[choice_tuple[0]] = choice_tuple[1];
          return {
            label: choice_tuple[1],
            id: this.id + "__" + index,
            value: uR.slugify(choice_tuple[0])
          };
        }.bind(this));
      }
      this.className = this.name + " " + this.type + " " + uR.config.form.field_class;
      var element = document.createElement(this.tagname);
      this.field_tag = riot.mount(element, { field: this, parent: this.form, is_ur_input: true })[0];
    }

    _createClass(URInput, [{
      key: "onKeyUp",
      value: function onKeyUp(e) {
        if (this.no_validation) {
          return;
        }
        if (e.type == "keyup") {
          self.active = true;
        }
        this.value = e.value || e.target && e.target.value || ""; // e.value is a way to fake events
        this.changed = this.last_value == this.value;
        this.last_value = this.value;
        this.empty = !this.value.length;
        var invalid_email = !/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.value);
        if (!this.required && !this.value) {
          invalid_email = false;
        }
        var was_valid = this.valid;
        this.valid = false;
        if (!this.required && this.empty) {
          this.valid = true;
        } else if (this.required && this.empty) {
          this.data_error = "This field is required.";
        } else if (this.value.length < this.minlength) {
          var type = ["number", "tel"].indexOf(this.type) == -1 ? " characters." : " numbers.";
          this.data_error = this.verbose_name + " must be at least " + this.minlength + type;
        } else if (this.maxlength && this.value.length > this.maxlength) {
          var type = ["number", "tel"].indexOf(this.type) == -1 ? " characters." : " numbers.";
          this.data_error = this.verbose_name + " cannot be more than " + this.maxlength + type;
        } else if (this.type == "email" && invalid_email) {
          this.data_error = "Please enter a valid email address.";
        } else if (!this.validate(this.value, this)) {} //everything is handled in the function
        else {
            this.valid = true;
          }
        if (was_valid != this.valid) {
          this.form.form_tag.update();
        }
        this.form.form_tag.onChange(e);
        //#! if (!this.data_error) { this.opts.ur_form.keyUp(this) }
        //#! if (!this.data_error && e.type == "blur") { this._validate(this.value,this); }
      }
    }, {
      key: "onFocus",
      value: function onFocus(e) {
        // activate and show error for last field (if not first)
        this.activated = true;
        var last = this.form.field_list[this._field_index - 1];
        if (last) {
          last.show_error = true;
        }
        this.form.form_tag.update();
      }
    }, {
      key: "onBlur",
      value: function onBlur(e) {
        // deactivate, force reevaluation, show errors
        uR.onBlur(this);
        this.activated = false;
        this.last_value = undefined; // trigger re-evaluation
        this.onChange(e);
        this.form.form_tag.update();
      }
    }, {
      key: "onChange",
      value: function onChange(e) {
        if (this.form.active) {
          this.show_error = true;
        }
        this.form.onChange && this.form.onChange(e, this);
        this.onKeyUp(e);
      }
    }, {
      key: "reset",
      value: function reset() {
        this.show_error = false;
        this.value = this.initial_value || "";
        var target;
        if (this.field_tag && this.input_tagname) {
          target = this.field_tag.root.querySelector(this.input_tagname);
          target.value = this.value;
        }
        this.onKeyUp({ target: target });
        this.activated = this.value != "";
        this.field_tag.update();
      }
    }]);

    return URInput;
  }();
  uR.form.fields = {
    'ur-input': uR.form.URInput
  };
})();

riot.tag2('image-input', '<img if="{initial_value}" riot-src="{initial_value}"> <input type="file" name="{name}" onchange="{onChange}">', '', '', function (opts) {
  this.on("mount", function () {
    this.name = this.opts.parent._name;
    this.update();
  });
  this.onChange = function (e) {
    var files = e.target.files;
    this.opts.parent.onChange(e);
  }.bind(this);
});

riot.tag2('ur-input', '', '', '', function (opts) {

  var self = this;

  this.on("mount", function () {
    this._input = document.createElement(this.field.input_tagname);
    if (this.field.input_tagname != "textarea") {
      this._input.type = this.field.input_type;
    }
    this._input.name = this.field.name;
    this._input.id = this.field.id;
    this._input.addEventListener("change", this.field.onChange.bind(this.field));
    this._input.addEventListener("focus", this.field.onFocus.bind(this.field));
    this._input.addEventListener("blur", this.field.onBlur.bind(this.field));
    this._input.addEventListener("keyup", this.field.onKeyUp.bind(this.field));
    this._input.classList.add(uR.theme.input);
    if (this.field.input_type == "header") {
      this._input.style.display = "none";
      this.field.required = false;
    }
    if (this.input_type == "hidden") {
      this.root.style.display = "none";
      this.label = "";
    }
    this.root.appendChild(this._input);

    var i_tries = 0;
    var interval = setTimeout(function () {
      var e = document.querySelector("#" + self.id);
      i_tries += 1;
      if (e && (i_tries++ > 5 || e.value)) {
        clearInterval(interval);
        self.onKeyUp({ target: e });
      }
    }, 1000);
    this.field.onMount && setTimeout(this.field.onMount.bind(this.field), 0);
    if (this.extra_attrs) {
      for (k in this.extra_attrs) {
        this.root.querySelector("input").setAttribute(k, this.extra_attrs[k]);
      }
    }
    this.update();
  });
});

riot.tag2('ur-form', '<div class="{theme.outer}"> <div class="{theme.header}" if="{form_title}"><h3>{form_title}</div> <div class="{theme.content}"> <div class="rendered_content"></div> <form autocomplete="off" onsubmit="{submit}" name="form_element" class="{opts.form_class}" method="{opts.method}"> <yield from="pre-form"></yield> <div each="{form.field_list}" class="{className} {empty: empty, invalid: !valid && show_error, active: activated || !empty} ur-input" data-field_id="{id}"> <div class="help_click" if="{help_click}" onclick="{help_click.click}" title="{help_click.title}">?</div> <label for="{id}" if="{label}" class="{required: required}" onclick="{labelClick}" data-success="{data_success}">{label}</label> <div class="{uR.theme.error_class}">{data_error}</div> <div class="help_text" if="{help_text}"><i class="fa fa-question-circle-o"></i> {help_text}</div> </div> <div if="{non_field_error}" class="non_field_error" data-field_id="non_field_error"> <div class="{uR.theme.error_class}">{non_field_error}</div> <p if="{uR.config.support_email}" style="text-align: center;"> If you need assistance contact <a href="mailto:{uR.config.support_email}">{uR.config.support_email}</a> </p> </div> <div class="button_div"> <yield from="button_div"></yield> <button class="{btn_success} {disabled: !valid}" id="submit_button" onclick="{submit}"> {success_text}</button> <button class="{btn_cancel}" if="{opts.cancel_function}" onclick="{opts.cancel_function}"> {cancel_text}</button> </div> <ul class="messagelist" if="{messages.length}"> <li class="{level}" each="{messages}">{body}</li> </ul> </form> <ur-pagination></ur-pagination> </div> </div>', '', '', function (opts) {

  var self = this;
  this.btn_success = this.opts.btn_success || uR.config.btn_success;
  this.btn_cancel = this.opts.btn_cancel || uR.config.btn_cancel;
  this.cancel_text = this.opts.cancel_text || uR.config.cancel_text;
  this.success_text = this.opts.success_text || "Submit";
  this.onChange = this.opts.onChange;
  this.suffix = this.opts.suffix || "";

  this.submit = function (e, _super) {
    if (this._ajax_busy || !this.form.field_list.length) {
      return;
    }
    if (!this.valid) {
      uR.forEach(this.form.field_list, function (field) {
        field.show_error = true;
      });
      this.update();
      return;
    }

    this.non_field_error = undefined;
    var alt_submit = this.opts.submit || this.parent && this.parent.submit;
    if (!_super && alt_submit) {
      if (alt_submit == "noop") {
        var form = this.root.querySelector("form");
        if (form.method == "POST" && document.querySelector("[name=csrfmiddlewaretoken]")) {
          var e = document.createElement('input');
          e.type = "hidden";
          e.name = "csrfmiddlewaretoken";
          e.value = document.querySelector("[name=csrfmiddlewaretoken]").value;
          form.appendChild(e);
        }
        form.submit();
      } else {
        alt_submit(this);
      }
    } else {
      uR.ajax({
        url: this.form.action,
        method: this.opts.method,
        data: this.getData(),
        success: this.ajax_success,
        success_attribute: this.opts.success_attribute,
        error: this.ajax_error,
        tag: self
      });
    }
  }.bind(this);

  this.clear = function () {
    this.initial = this.empty_initial;
    uR.storage.set(this.form.action, null);
    uR.forEach(this.form.field_list, function (field) {
      field.initial_value = self.initial[field.name];
      field.child && field.child.clear && field.child.clear();
      field.reset();
    });
    this.messages = [];
    self.active = false;
    setTimeout(function () {
      var f = self.root.querySelector("input:not([type=hidden]),select,textarea");f && f.focus();
    }, 0);
  }.bind(this);

  this.getData = function () {
    var data = {};
    uR.forEach(this.form.field_list, function (f) {
      data[f.name] = f.value || "";
    });
    return data;
  }.bind(this);

  this.on("mount", function () {
    var _parent = this.parent || {};
    _parent.ur_form = this;
    _parent.opts = _parent.opts || {};
    this.ajax_success = this.opts.ajax_success || _parent.opts.ajax_success || _parent.ajax_success || function () {};
    if (this.opts.success_redirect) {

      this._ajax_success = this.ajax_success;
      this.ajax_success = function () {
        self._ajax_success();window.location = this.opts.success_redirect;
      };
    }
    this.ajax_error = this.opts.ajax_error || _parent.opts.ajax_error || _parent.ajax_error || function () {};
    this.ajax_target = this.opts.ajax_target || this.submit_button;
    this.form = new uR.form.URForm(this);
    this.update();
    this.update();
    this.root.style.opacity = 1;
    if (this.opts.autosubmit) {
      this.root.querySelector("#submit_button").style.display = "none";
      this.opts.autosubmit == "first" && this.onChange({}, true);
    }
  });

  this.onChange = function (e, force) {
    if (this._ajax_busy) {
      return;
    }
    if (!this.opts.autosubmit) {
      return;
    }
    var changed;
    uR.forEach(this.form.field_list || [], function (field) {
      changed = changed || field.changed;
      field.changed = false;
    });
    if (changed || force) {
      this.update();
      this.submit();
    }
  }.bind(this);

  this.on("update", function () {
    if (this.root.querySelectorAll(".ur-input").length == 0) {
      return;
    }
    this.form.renderFields();
    if (this._multipart) {
      this.form_element.enctype = 'multipart/form-data';
    }
    this.valid = true;
    if (!this.form.field_list) {
      return;
    }
    uR.forEach(this.form.field_list, function (field, i) {
      if (field.html_error) {
        var error_element = this.root.querySelector("[data-field_id=" + field.id + "] .error");
        if (field.id && error_element) {
          error_element.innerHTML = field.html_error;
        }
      }
      if (field.no_validation) {
        return;
      }
      self.valid = self.valid && field.valid;
    }.bind(this));
    if (self.non_field_error && self.non_field_html_error) {
      setTimeout(function () {
        if (self.root.querySelector("[data-field_id=non_field_error]")) {
          self.root.querySelector("[data-field_id=non_field_error]").innerHTML = self.non_field_error;
        }
      }, 0);
    }
    this.opts.autosave && this.autoSave();
    if (this.rendered_content) {

      this.root.querySelector(".rendered_content").innerHTML = this.rendered_content;
      this.rendered_content = undefined;
    }
  });

  this.autoSave = uR.dedribble(function () {

    var new_data = this.getData();

    uR.storage.set(this.form.action, new_data);
  }.bind(this), 1000);
});

riot.tag2('ur-formset', '<ur-form each="{form,i in forms}" suffix="{⁗_⁗+i}" success_text="Add"> <div class="message font-20" if="{next}"> <b>{name}</b> has been successfully added!<br> Add more children or click <b>Next</b> to continue. </div> </ur-form> <button class="{uR.config.btn_primary}" disabled="{!valid}">Next</button>', '', '', function (opts) {
  var self = this;
  this.forms = [];
  this.on("mount", function () {
    this.forms.push({ schema: this.opts.schema });
    this.update();
  });
  this.submit = function (element) {
    var form_data = {};
    for (var key in element.inputs) {
      form_data[key] = element.inputs[key].value;
    }
    uR.ajax({
      method: "POST",
      url: this.form.action,
      data: form_data,
      target: element.root,
      self: element,
      loading_attribute: "mask",
      success: function success(data) {
        element.name = form_data.name;self.update();
      }
    });
  }.bind(this);
});

(function () {
  uR.config.input_overrides.checkbox = uR.config.input_overrides["checkbox-input"] = "checkbox-input";
  uR.config.input_overrides.radio = uR.config.input_overrides["radio-input"] = "checkbox-input";
  uR.form.fields['checkbox-input'] = function (_uR$form$URInput) {
    _inherits(CheckboxInput, _uR$form$URInput);

    function CheckboxInput(form, options) {
      _classCallCheck(this, CheckboxInput);

      var _this = _possibleConstructorReturn(this, (CheckboxInput.__proto__ || Object.getPrototypeOf(CheckboxInput)).call(this, form, options));

      if (_this.type == "checkbox-input") {
        _this.type = "checkbox";
      }
      _this.initial = _this.initial_value;
      if (typeof _this.initial == "string") {
        _this.initial = _this.initial.split(",");
      }
      _this.last_value = _this.initial;
      if (!(_this.choices && _this.choices.length)) {
        _this.choices = [{
          label: _this.label,
          id: _this.id + "__" + 0,
          value: "true"
        }];
        _this.field_tag.root.classList.add("no-label");
      }
      return _this;
    }

    _createClass(CheckboxInput, [{
      key: "reset",
      value: function reset() {
        this.show_error = false;
        this.value = this.initial || [];
        var target;
        uR.forEach(this.value, function (slug) {
          var cb = this.field_tag.root.querySelector("[value=" + slug + "]");
          if (cb) {
            cb.checked = true;target = cb;
          }
        }.bind(this));
        this.onKeyUp({ target: target });
        this.field_tag.update();
      }
    }, {
      key: "onKeyUp",
      value: function onKeyUp(e) {
        this.changed = false;
        this.valid = true;
        this.value = [];
        this.last_value = this.last_value || [];
        uR.forEach(this.field_tag.root.querySelectorAll("[name=" + this.name + "]"), function (input) {
          this.changed = this.changed || this.last_value.indexOf(input.value) != -1 !== input.checked;
          if (input.checked) {
            this.value.push(input.value);
          }
        }.bind(this));
        if (this.required && !this.value.length) {
          this.data_error = "This field is required.";
          this.valid = this.value.length;
        }
        this.show_error = true;
        this.last_value = this.value;
        this.form.form_tag.update();
        this.form.form_tag.onChange();
      }
    }]);

    return CheckboxInput;
  }(uR.form.URInput);
})();

riot.tag2('checkbox-input', '<div each="{field.choices}" class="choice"> <input type="{parent.field.type}" id="{id}" riot-value="{value}" onchange="{onKeyUp}" onblur="{onKeyUp}" name="{parent.field.name}"> <label for="{id}">{label}</label> </div>', '', '', function (opts) {

  var self = this;
  this.onKeyUp = function (e) {
    this.field.onKeyUp(e);
  }.bind(this);
});

(function () {
  uR.config.input_overrides.select = uR.config.input_overrides["select-input"] = "select-input";
  uR.form.fields['select-input'] = function (_uR$form$URInput2) {
    _inherits(SelectInput, _uR$form$URInput2);

    function SelectInput(form, options) {
      _classCallCheck(this, SelectInput);

      options.input_tagname = "select";
      return _possibleConstructorReturn(this, (SelectInput.__proto__ || Object.getPrototypeOf(SelectInput)).call(this, form, options));
    }

    return SelectInput;
  }(uR.form.URInput);
})();

riot.tag2('select-input', '<select id="{field.id}" onchange="{onChange}" onblur="{onBlur}" class="browser-default" name="{field.name}"> <option each="{field.choices}" riot-value="{value}">{label}</option> </select>', '', '', function (opts) {

  this.onBlur = function (e) {
    this.field.onBlur(e);
  }.bind(this);
  this.onChange = function (e) {
    this.field.onChange(e);
  }.bind(this);

  this.on("mount", function () {
    this.update();
  });
});

riot.tag2('ur-pagination', '<div class="flex-row" each="{uR.pagination.results}"> <div class="col1"> <a href="{url}" class="fa fa-link" if="{url}"></a> <a href="{ur_admin}" class="{uR.icon.admin}" if="{ur_admin}"></a> </div> <div class="col4" each="{field in fields}">{field}</div> </div>', '', '', function (opts) {

  this.on("update", function () {
    uR.pagination && uR.forEach(uR.pagination.results, function (result) {
      result.ur_admin = uR.config.form_prefix.replace("^#?", "") + "/" + uR.form.current_form + "/" + result.id + "/";
    });
  });
});

uR.mount_tabs = true;
uR.ready(function () {
  if (uR._mount_tabs) {
    riot.mount("ur-tabs");
  }
});
riot.tag2('ur-tabs', '<div class="tab-wrapper"> <div class="tab-anchors"> <a onclick="{showTab}" each="{tab,i in tabs}" title="{tab.title}" class="{active: i == this.active}"> {tab.title}</a> </div> <yield></yield> </div>', '', '', function (opts) {

  this.showTab = function (e) {
    this.active = e.item.i;
  }.bind(this);

  this.on("mount", function () {

    this.tabs = this.tags['ur-tab'] || [];
    if (!Array.isArray(this.tabs)) {
      this.tabs = [this.tabs];
    }
    uR.forEach(this.opts.tabs || [], function (tab) {
      var e = document.createElement('ur-tab');
      this.root.querySelector(".tab-wrapper").appendChild(e);
      tab.parent = this;
      this.tabs.push(riot.mount(e, tab)[0]);
    }.bind(this));
    uR.forEach(this.tabs, function (tab, i) {
      tab.index = i;
    });
    this.active = 0;
    if (uR.config.default_tabs) {
      this.root.classList.add("default");
    }
    this.update();
  });
});

riot.tag2('ur-tab', '<yield></yield>', '', '', function (opts) {

  this.title = this.opts.title || this.title;

  this.show = function () {
    this.root.classList.remove("hidden");
    if (this.opts.href && !this.loaded) {
      return this.ajax({
        url: this.opts.href,
        success: function success(data, response) {
          this.root.innerHTML = data.content || response.response;this.loaded = true;
        }
      });
    }
    this.opts.click && this.opts.click();
  }.bind(this);

  this.hide = function () {
    this.root.classList.add("hidden");
  }.bind(this);

  this.on("mount", function () {
    this._parent = this.parent || this.opts.parent;
    if (this.opts.innerHTML) {
      this.root.innerHTML = this.opts.innerHTML;
    }
  });

  this.on("update", function () {
    if (!this.parent || this._parent.active == undefined) {
      return;
    }
    (this._parent.active == this.index ? this.show : this.hide)();
  });
});

riot.tag2('markdown', '<yield></yield>', '', '', function (opts) {
  this.on("mount", function () {
    var content = this.content || this.opts.content || this.root.innerHTML;
    if (this.opts.url && !content) {
      uR.ajax({
        url: this.opts.url,
        success: function (data, request) {
          this.opts.content = request.responseText;
          this.mount();
        }.bind(this)
      });
      return;
    }
    this.root.innerHTML = markdown.toHTML(content.replace("&amp;", "&"));
  });
  this.setContent = function (content) {
    this.content = content;
    this.mount();
  }.bind(this);
});

uR.config.input_overrides['multi-file'] = uR.config.input_overrides["multi-file"] = "multi-file";
uR.config.tmp_file_url = "/media_files/private/";
uR.form.fields['multi-file'] = function (_uR$form$URInput3) {
  _inherits(MultiFileInput, _uR$form$URInput3);

  function MultiFileInput(form, options) {
    _classCallCheck(this, MultiFileInput);

    return _possibleConstructorReturn(this, (MultiFileInput.__proto__ || Object.getPrototypeOf(MultiFileInput)).call(this, form, options));
  }

  return MultiFileInput;
}(uR.form.URInput);

riot.tag2('multi-file', '<form action="{action}" method="POST" if="{can_upload}"> <label class="{uR.config.btn_primary}"> <input type="file" onchange="{validateAndUpload}" style="display:none;" name="file"> {upload_text} </label> </form> <div each="{files}" class="file {uR.config.alert_success}"> <div> <div class="name">{name}</div> <div class="content_type">{content_type}</div> </div> <div onclick="{parent.deleteFile}" class="fa fa-trash"></div> </div> <div if="{error_msg}" class="{uR.theme.error_class}">{error_msg}</div>', '', '', function (opts) {

  var self = this;
  this.validateAndUpload = function (e) {
    var form = this.root.querySelector("form");
    this.error_msg = undefined;
    this.ajax({
      form: form,
      success: function success(data) {
        this.files.push(data);
        uR.storage.set(this.action + "__files", this.files);
      },
      error: function error(data) {
        self.error_msg = "An unknown error has occurred.";
      }
    });
    this.root.querySelector("[type=file]").value = "";
  }.bind(this);
  this.clear = function () {
    this.value = "";
    this.files = [];
    uR.storage.set(this.action + "__files", undefined);
    this.update();
  }.bind(this);
  this.deleteFile = function (e) {
    uR.forEach(this.files, function (f, i) {
      if (f.id == e.item.id) {
        self.files.splice(i, 1);
      }
    });
    uR.storage.set(this.action + "__files", this.files);
  }.bind(this);
  this.on("mount", function () {
    this.max_files = this.field.max_files || Infinity;
    this.action = opts.action || uR.config.tmp_file_url;
    this.files = uR.storage.get(this.action + "__files") || [];
    this.update();
  });
  this.on("update", function () {
    if (this.files && this.files.length) {
      this.upload_text = opts.parent.upload_another_text || opts.parent.upload_text || "Upload another file";
      this.field.value = this.files.map(function (f) {
        return f.id;
      }).join(",");
    } else {
      this.upload_text = this.field.upload_text || 'Upload a file';
      this.value = "";
    }
    this.can_upload = !(this.files && this.files.length >= this.max_files);
  });
});

riot.tag2('ez-file', '<input type="file" id="{_id}" onchange="{cropIt}" name="{slug}" if="{can_edit}"> <yield> <label if="{!done}" for="{_id}" class="btn-danger btn">Add {name}</label> <button if="{done && !bg}" class="btn btn-success" onclick="{edIt}">{opts.success_text || ⁗Edit⁗}</button> <div if="{bg}" class="image" riot-style="background-image: url({bg})" onclick="{edIt}"></div> <button if="{done}" onclick="{clear}" class="{uR.config.btn_cancel} fa fa-trash"></button> </yield> <form action="{opts.url}" method="POST"> <input type="hidden" name="user_id" riot-value="{opts.user_id}"> <input type="hidden" name="blob"> </form>', 'ez-file { display: block; position: relative; } ez-file input[type=file], ez-file form { display: none; } ez-file .image { cursor: pointer; display: inline-block; width: 100%; } ez-file .image:before { content: ""; display: block; margin-top: 100%; } ez-file .fa-trash { bottom: 0; left: 0; position: absolute; }', '', function (opts) {

  var self = this;
  this.on("mount", function () {
    this.slug = (this.opts.name || "").replace(" ", "_").toLowerCase();
    this.name = this.opts.name || "File";
    this.done = this.opts.done;
    this.type = this.opts.type;
    this.can_edit = this.opts.can_edit || this.opts.can_edit == undefined;
    this.show_preview = !this.opts.no_preview;
    this._id = "file__" + this.slug + "__" + this.opts.user_id;
    this.update();
  });
  this.on("update", function () {
    if (this.done && this.show_preview) {
      this.bg = this.done;
    }
  });
  this.clear = function (e) {
    if (!e.target.innerHTML) {
      e.target.innerHTML = "?";
      setTimeout(function () {
        e.target.innerHTML = "";
      }, 2000);
      return;
    }
    this.uploadFile(e, { action: 'delete', user_id: opts.user_id });
  }.bind(this);
  this.cropIt = function (e) {
    var file = this.root.querySelector("[type=file]").files[0];

    var img = document.createElement("img");
    var reader = new FileReader();
    reader.onload = function (e) {
      if (this.opts.type == "img") {
        img.src = e.target.result;
      } else {
        this.root.querySelector("[name=blob]").value = e.target.result;
        this.uploadFile(e);
      }
    }.bind(this);
    reader.readAsDataURL(file);

    img.onload = function () {
      uR.alertElement("resize-image", { img: img, parent: self });
    };
  }.bind(this);
  this.edIt = function (e) {
    if (!this.can_edit) {
      return;
    }
    img = document.createElement("img");
    img.onload = function () {
      uR.alertElement("resize-image", { img: img, parent: self });
    };
    img.src = this.done;
  }.bind(this);
  this.uploadFile = function (e, data) {
    var form = this.root.querySelector("form");
    this.ajax({
      url: this.opts.url,
      method: "POST",
      data: data,
      form: form,
      success: function success(data) {
        this.done = data.done;
      },
      error: function error() {
        uR.alert("an unknown error has occurred. Go bug Chris!");
      }
    });
  }.bind(this);
});
riot.tag2('resize-image', '<div class="{theme.outer}"> <div class="{theme.content}"> <center> <canvas></canvas> <div class="burtons"> <button onclick="{doZoom}" class="{uR.config.btn_primary}"> {zoom}x <i class="fa fa-search-plus"></i></button> <button onclick="{doRotate}" class="{uR.config.btn_primary}"><i class="fa fa-rotate-right"></i></button> <label for="{opts.parent._id}" class="{uR.config.btn_success}"><i class="fa fa-camera"></i></label> </div> <button onclick="{done}" class="{uR.config.btn_success}">Save</button> <button onclick="{cancel}" class="{uR.config.btn_cancel}">Cancel</button> </center> </div> </div>', 'resize-image .btn { font-size: 1.5em; margin: 0 5px; } resize-image .burtons .btn { margin-bottom: 10px; }', '', function (opts) {

  this.on("mount", function () {
    this.canvas = this.root.querySelector("canvas");
    this.ctx = this.canvas.getContext("2d");
    this.img = this.opts.img;
    this.ratio = this.img.width / this.img.height;
    var max_width = this.canvas.parentElement.clientWidth;
    this.canvas.width = max_width;
    this.canvas.height = max_width / this.ratio;
    this.zoom = 1;
    this.rotate = 0;
    this.file = this.opts.parent.root.querySelector("[type=file]");
    this.blob = this.opts.parent.root.querySelector("[name=blob]");
    this.file.value = "";
    this.blob.value = "";
    this.update();
  });
  this.on("update", function () {
    if (!this.img) {
      return;
    }
    var canvas = this.canvas;
    var dx = (this.zoom - 1) * canvas.width;
    var dy = (this.zoom - 1) * canvas.height;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.ctx.drawImage(this.opts.img, -dx, -dy, canvas.width + 2 * dx, canvas.height + 2 * dy);

    var img = document.createElement('img');
    img.src = canvas.toDataURL("image/png");
    img.onload = function () {
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.ctx.save();
      this.ctx.translate(canvas.width / 2, canvas.height / 2);
      this.ctx.rotate(this.rotate * Math.PI);
      this.ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2);
      this.ctx.restore();
    }.bind(this);
  });
  this.cancel = function (e) {
    this.unmount();
  }.bind(this);
  this.doZoom = function (e) {
    this.zoom += 0.5;
    if (this.zoom > 3) {
      this.zoom = 1;
    }
  }.bind(this);
  this.doRotate = function (e) {
    this.rotate += 0.5;
  }.bind(this);
  this.done = function (e) {
    this.blob.value = this.canvas.toDataURL();
    this.opts.parent.uploadFile();
    this.unmount();
  }.bind(this);
});

uR.ready(function () {
  riot.mount("ur-nav");
});

riot.tag2('ur-nav', '<nav> <div class="nav-wrapper"> <a href="/about/" class="brand-logo" style="height: 100%;"> <img riot-src="{uR.config.logo || \'logo.png\'}" style="height: 100%"> </a> <ul id="nav-mobile" class="right hide-on-med-and-down"> <yield></yield> <auth-dropdown></auth-dropdown> </ul> </div> </nav>', '', '', function (opts) {});