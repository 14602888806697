"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  var Storage = function () {
    function Storage(prefix) {
      _classCallCheck(this, Storage);

      this.PREFIX = prefix || "";
      this.META = this.PREFIX + "META/";
      this.EXIPIRY = '__expiry/'; // Key used for expiration storage
      this.default_expire_ms = 10 * 60 * 1000; // ten minutes
      this.defaults = {}; // table with default values
      this.times = {};
      if (!this.test_supported()) {
        console.warn("Storage not supported, falling back to dummy storage");
        this.FAKE_STORAGE = {};
        this.set = function (key, value) {
          this.FAKE_STORAGE[key] = value;
        };
        this.get = function (key) {
          return this.FAKE_STORAGE[key];
        };
        this.has = function (key) {
          this.FAKE_STORAGE.hasOwnProperty(key);
        };
        this.remove = function (key) {
          delete this.FAKE_STORAGE[key];
        };
      }
      //this.times = this.get(this.META+"times") || {};
    }

    _createClass(Storage, [{
      key: "get",
      value: function get(k) {
        // pull a json from local storage or get an object from the defaults dict
        var key = this.PREFIX + k;
        var value;
        if (localStorage.hasOwnProperty(key)) {
          try {
            value = JSON.parse(localStorage.getItem(key));
          } catch (e) {} // we only allow JSON here, so parse errors can be ignored
        } else if (this.defaults.hasOwnProperty(key)) {
          value = this.defaults[key];
        }
        return value;
      }
    }, {
      key: "set",
      value: function set(k, value) {
        // store stringified json in localstorage
        var key = this.PREFIX + k;
        if (!value && value !== 0) {
          localStorage.removeItem(key);return;
        }
        localStorage.setItem(key, JSON.stringify(value));
        this.times[key] = new Date().valueOf();
        this._saveTime();
      }
    }, {
      key: "has",
      value: function has(key) {
        return localStorage.hasOwnProperty(key);
      }
    }, {
      key: "remove",
      value: function remove(k) {
        var key = this.PREFIX + k;
        localStorage.removeItem(key);
        delete this.times[key];
        this._saveTime();
      }
    }, {
      key: "_saveTime",
      value: function _saveTime() {
        localStorage.setItem(this.META + 'times', JSON.stringify(this.times));
      }
    }, {
      key: "test_supported",
      value: function test_supported() {
        // incognito safari and older browsers don't support local storage. Use an object in ram as a dummy
        try {
          localStorage.setItem('test', '1');
          localStorage.removeItem('test');
          return true;
        } catch (e) {}
      }

      // below this is the api for the timebomb remote data store, which isn't used anywhere yet.
      // a dummy version of this would just allways execute a remote lookup and then callback.

    }, {
      key: "isExpired",
      value: function isExpired(key) {
        var expire_ms = this.get(this.EXPIRY + key) || this.setExpire(key);
        return expire_ms < new Date().valueOf();
      }
    }, {
      key: "setExpire",
      value: function setExpire(key, epoch_ms) {
        epoch_ms = epoch_ms || this.default_expire_ms + new Date().valueOf();
        this.set(this.EXPIRY + key, epoch_ms);
        return epoch_ms;
      }
    }, {
      key: "remote",
      value: function remote(url, callback) {
        var stored = this.get(url);
        if (stored && !this.isExpired(url)) {
          callback(stored);return;
        }
        uR.ajax({
          url: url,
          success: function (data) {
            this.set(url, data);
            this.setExpire(url);
            callback(data);
          }.bind(this)
        });
      }
    }]);

    return Storage;
  }();

  uR.storage = new Storage();
  uR.Storage = Storage;
})();